import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

import HeroPhoto from '../components/sections/hero-photo'
import SectionHeader from '../components/common/section-header'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-googleads.scss'
import { googleads } from '../utils/schema/services'
import { faqGoogleAds } from '../utils/schema/faq'

const pageGoogleads = ({ location }) => {
    const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-googleads"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-googleads.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  photo1: file(relativePath: {eq: "googleads/Photo1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  photo2: file(relativePath: {eq: "googleads/Photo2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo3: file(relativePath: {eq: "googleads/Photo3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo4: file(relativePath: {eq: "googleads/Photo4.png"}) {
    childImageSharp {
      gatsbyImageData(width: 390, layout: CONSTRAINED)
    }
  }
  photo5: file(relativePath: {eq: "googleads/Photo5.png"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo6: file(relativePath: {eq: "googleads/Photo6.png"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo7: file(relativePath: {eq: "googleads/Photo7.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  photo8: file(relativePath: {eq: "googleads/Photo8.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo9: file(relativePath: {eq: "googleads/Photo9.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 540, layout: CONSTRAINED)
    }
  }
  photo10: file(relativePath: {eq: "googleads/Photo10.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  display: file(relativePath: {eq: "googleads/1_Display.png"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  search: file(relativePath: {eq: "googleads/1_Search.png"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  shopping: file(relativePath: {eq: "googleads/3_Shopping.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  remarketing: file(relativePath: {eq: "googleads/4_Remarketing.png"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

    return <>
        <MetaData
            data={data}
            location={location}
            type="website"
        />
        <Helmet>
            <style type="text/css">{`${page.codeinjection_styles}`}</style>
            <script type="application/ld+json">{JSON.stringify(googleads)}</script>
            <script type="application/ld+json">{JSON.stringify(faqGoogleAds)}</script>
        </Helmet>
        <Layout viewport={''}>
            <article className="page-googleads">
                <HeroPhoto
                    headerTitle={"Google Ads - PPC Management"}
                    ctaImage={data.hero.childImageSharp.gatsbyImageData}
                />
                <section className="mt-5">
                    <div className="container small">
                        <div className="row">
                            <div className="col-12">
                                <p className="h3 mb-5 text-align-center">Έχετε ακούσει από γνωστούς και φίλους πόσο αποδοτική είναι η διαφήμιση στη Google και επιθυμείτε και εσείς να κάνετε μία επένδυση και να δείτε τι θα αποφέρει αυτή;</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="h3 mb-5 color--primary text-align-center">Τα ερωτήματα, πολλά :</p>
                            </div>
                        </div>

                    </div>
                    <div className="container small">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <GatsbyImage
                                    image={data.photo1.childImageSharp.gatsbyImageData}
                                    alt='google ads ερωτήσεις'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-6">
                                <p>- Ποιός είναι ο κατάλληλος συνεργάτης,</p>
                                <p>- Ποιό είναι το κατάλληλο budget για να ξεκινήσετε,</p>
                                <p>- Τι να περιμένετε από αυτή την επένδυση,</p>
                                <p>- Είναι ορθά δομημένη η ιστοσελίδα σας για να υποδεχτεί κόσμο<br />και να τον μετατρέψει σε πελάτες,</p>
                                <p>- Και πολλά πολλά ακόμη…</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <p className="my-5">Η Think Plus, ως Google partner εδώ και πολλά χρόνια θα προσπαθήσει όχι μόνο να σας δώσει μερικές απαντήσεις αλλά και τη λύση για όλα τα παραπάνω θέματα.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="googleads__campaigns mb-5 background__theme ">
                    <div className="container small">
                        <div className="row">
                            <div className="col">
                                <h2 className="h3 text-align-center"><span className="dec-element--line-horizontal" aria-hidden="true"></span>Kαμπάνιες Google Ads</h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-align-center">
                                <p>Ενώ λοιπόν η οργανική επισκεψιμότητα είναι ένα εξαιρετικό κανάλι επισκεψιμότητας και
                                επικοινωνίας, η
                                απόκτηση αποτελεσμάτων από τεχνικές SEO απαιτεί αρκετό χρόνο. Εάν πρέπει οπωσδήποτε, να είστε στην
                                πρώτη
                                σελίδα της Google για προκαθορισμένο χρόνο ώστε να υποστηρίξετε τις προσπάθειες της καμπάνιας
                                μάρκετινγκ, η διαφήμιση στη Google είναι ένα εξαιρετικό εργαλείο. Οι πληρωμένες καμπάνιες είναι
                                επίσης
                                εξαιρετικές για το brand awareness, το brand Loyalty αλλά και τη προστασία του brand από τους ανταγωνιστές. Σταματήστε άμεσα τις ενοχλητικές διαφημίσεις ανταγωνιστών πάνω στο brand σας.</p>
                                <p className="h4 color--primary">Πως;</p>
                                <strong className="mb-4">Με το να δημιουργήσουμε εξαιρετικές καμπάνιες για εσάς!</strong>
                                <p className="h4 color--primary">Γιατί να επιλέξετε τη Think Plus :</p>
                            </div>
                        </div>
                        <div className="row align-items-center pt-3">
                            <div className="col-md-4">
                                <GatsbyImage
                                    image={data.photo2.childImageSharp.gatsbyImageData}
                                    alt='Google Search Display Youtube Remarketing'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-8">
                                <h3 className="h6">Γιατί η ομάδα μας αποτελείται από πιστοποιημένα από τη Google στελέχη.</h3>
                                <p>Στελέχη τα οποία γνωρίζουν άψογα όλα τα είδη διαφήμισης στη Google. Search, Display, Youtube &amp;
                                Remarketing είναι μεθόδοι που εφαρμόζουν καθημερινά, ενώ κάθε χρόνο ξαναπερνούν εξετάσεις για να
                                ισχύει η πιστοποίησή τους και εκπαιδεύονται συνεχώς στα νέα χαρακτηριστικά και εργαλεία που
                  προσφέρονται.</p>
                            </div>
                        </div>
                        <div className="row align-items-center pt-5">
                            <div className="col-md-4 order-0 order-md-2">
                                <GatsbyImage
                                    image={data.photo3.childImageSharp.gatsbyImageData}
                                    alt='google ads related target'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-8 order-0 order-md-1">
                                <h3 className="h6">Είμαστε εύστοχοι και βελτιστοποιούμε την απόδοση του διαφημιστικού σας budget.</h3>
                                <p>Eκμεταλλευόμαστε για εσάς τους ήδη υπάρχοντες επισκέπτες, δημιουργούμε related κοινά με κοντινά χαρακτηριστικά και φυσικά βελτιώνουμε την απόδοση της διαφήμισής σας.</p>
                            </div>
                        </div>
                    </div>


                    <div className="container small">
                        <div className="row mb-5">
                            <div className="col pt-5">
                                <h3 className="h6">Google Ads Account Set Up. Αυξήστε τη προβολή της επιχείρησής σας στη Google.</h3>
                                <p>Πρώτα άπ’ όλα, αν δεν διαθέτετε έναν λογαριασμό Google Ads,
                                μπορούμε να φροντίσουμε για τη δημιουργία του από την αρχή μέχρι το τέλος.
                                Εδώ, σ’ αυτό το σημείο είναι εξαιρετικά σημαντικό να ενημερώσουμε τους πελάτες μας
                                σχετικά με τις πολιτικές προστασίας δεδομένων GDPR και την ασφάλεια πρόσβασης στο λογαριασμό.
                                Το όλο θέμα είναι να βεβαιωθείτε ότι δεν έχετε ποτέ προβλήματα πρόσβασης στο λογαριασμό σας ή με τα
                                δεδομένα που συλλέγετε.
                                Εστιάζουμε στη βελτιστοποίηση και την επίτευξη αποτελεσμάτων. Η ποιότητα της δουλειάς μας δεν εξαρτάται από το πόσο μεγάλο είναι το διαφημιστικό σας budget.</p>
                                <p>Πριν ξεκινήσουμε μαζί τη διαφήμιση στη Google, πρέπει να επιλέξουμε τους βασικούς δείκτες απόδοσης
                                (KPI)
                                που θα μας επιτρέψουν να βελτιστοποιήσουμε τις προσπάθειές μας και να παρακολουθήσουμε την πρόοδό
                                μας.
                                Στη συνέχεια, δημιουργούμε τις καμπάνιες και τις ρυθμίζουμε, προσθέτουμε τις λέξεις-κλειδιά, τα
                                λεκτικά
                                διαφημίσεων και άλλα.
                                Όταν πρόκειται για το Google Shopping, αυτή η διαδικασία είναι λίγο πιο περίπλοκη και απαιτεί
                                product
                feeds και έναν λογαριασμό Google Merchant Center.</p>
                                <p>Μετά τις δημιουργίες των καμπανιών διαφημίσεων στη Google,
                                είναι πρωταρχικής σημασίας να συνεχίσουμε τη βελτιστοποίηση των διαφημιστικών σας καμπανιών για
                                κάποιους
                μήνες για να βεβαιωθούμε ότι αξιοποιούμε πλήρως την πλατφόρμα.</p>
                            </div>
                        </div>
                        <div className="row mb-5 align-items-center">
                            <div className="col-md-3">
                                <GatsbyImage
                                    image={data.photo4.childImageSharp.gatsbyImageData}
                                    alt='διαφήμιση στη Google'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-9">
                                <h3 className="h6">Έλεγχος, Παρακολούθηση και Αξιολόγηση των καμπανιών για διαφήμιση στη Google. Εκμεταλλευθείτε στο έπακρο τις δυνατότητες της πλατφόρμας.</h3>
                                <p>
                                    Εάν διαθέτετε ήδη έναν λογαριασμό διαφημίσεων Google, όπου εκτελούνται καμπάνιες, αλλά δεν είστε
                                    σίγουροι πόσο αποδοτικές και σωστές είναι, χρειάζεστε έναν έλεγχο. Αυτή η υπηρεσία στοχεύει στην
                                    ανάλυση
                                    κάθε λεπτομέρειας του λογαριασμού, προκειμένου να βρει τρόπους για να τον βελτιστοποιήσει και να
                αναπτύξει νέες στρατηγικές για να πάρει περισσότερα, ποιοτικότερα και καλύτερα αποτελέσματα.</p>
                                <p>Ο ορθός έλεγχος διαφημίσεων Google μας επιτρέπει να ελέγξουμε ότι ισχύουν όλες οι καλές πρακτικές
                                και
                                ότι ο προϋπολογισμός σας χρησιμοποιείται σωστά. Αναθεωρούμε τις “λέξεις και φράσεις κλειδιά” για τις
                                καμπάνιες Search, και τα ad copies, βάσει περιεχομένου, κειμένου, εικόνων, βίντεο κτλ που
                                χρησιμοποιείτε, αλλά και τις μετατροπές βάσει των οποίων βελτιστοποιείτε τους λογαριασμούς σας. Ας
                                μην
                                ξεχνάμε τα κοινά-στόχο, τις αρνητικές λέξεις-κλειδιά, τις στρατηγικές προσφορών, τη γεωγραφική
                                στόχευση,
                                τις επεκτάσεις και όλες τις άλλες λεπτομέρειες που πρέπει να ελέγξουμε για να έχετε καλύτερη απόδοση
                διαφημιστικής επένδυσης (ROI).</p>
                                <p>Αυτή η πραγματικά βαθιά ανάλυση έχει ως στόχο να εντοπίσει όλες τις βελτιστοποιήσεις που μπορούμε
                                να
                                πραγματοποιήσουμε και να εφαρμόσουμε στις καμπάνιες για διαφήμιση στη Google. Θέλουμε να σας δώσουμε
                ιδιαίτερα σαφείς πληροφορίες σχετικά με τον τρόπο επίτευξης των βέλτιστων αποτελεσμάτων.</p>
                            </div>
                        </div>
                    </div>

                    <div className="container small">
                        <div className="row mb-5 align-items-center">
                            <div className="col-md-8">
                                <h3 className="h6">Βελτιστοποίηση της διαφήμισης στη Google.<br />Βελτιστοποιείστε τη διαφημιστική σας δαπάνη.</h3>
                                <p>
                                    Είτε πρόκειται για έλεγχο σε νέες καμπάνιες, είτε για καμπάνιες που εκτελούνται για μήνες ή και
                                    χρόνια,
                                    είναι σημαντικό να πραγματοποιείτε μια τακτική ανάλυση της απόδοσης του λογαριασμού και να κάνετε
                                    τις
                                    απαραίτητες βελτιστοποιήσεις. Επισημαίνουμε και πάλι. Δεν αρκεί από μόνη της η δημιουργία καμπανιών.
                                    Είναι εξαιρετικά σημαντικό να παρακολουθείτε την απόδοση και την ορθή λειτουργία του λογαριασμού,
                                    διαφορετικά κινδυνεύετε να πετάτε χρήματα.</p>
                            </div>
                            <div className="col-md-4">
                                <GatsbyImage
                                    image={data.photo5.childImageSharp.gatsbyImageData}
                                    alt='Βελτιστοποίηση της διαφήμισης στη Google'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-12">
                                <p>
                                    Η βελτιστοποίηση του λογαριασμού σας μπορεί να γίνει ad hoc (μετά από έλεγχο, εφαρμόζοντας τις
                                    προτεινόμενες ενέργειες), σε μια πολύ συγκεκριμένη περίοδο (λίγους μήνες μετά την έναρξη του
                                    λογαριασμού) ή πιο ορθά σε επαναλαμβανόμενη βάση (συντήρηση, κάθε μήνα). Όλα εξαρτώνται από τις
                                    ανάγκες
                                    σας και τις δυνατότητες αλλά και τα “θέλω” της επιχείρησής σας. Ωστόσο, αυτή η αναλυτική εργασία
                                    είναι
                                    σημαντική για να μεγιστοποιήσετε τις επενδύσεις σας και να διασφαλίσετε ότι οι προσπάθειές σας
                                    βρίσκονται στο σωστό δρόμο.</p>
                            </div>
                        </div>

                        <div className="row mb-5 align-items-center">
                            <div className="col-md-3">
                                <figure className="figure">
                                    <GatsbyImage
                                        image={data.photo6.childImageSharp.gatsbyImageData}
                                        alt='διαφημίσεις PPC'
                                        className="mb-4 mb-md-0" />
                                </figure>
                            </div>
                            <div className="col-md-9">
                                <h3 className="h6">Οδηγός τα έσοδα και όχι η διαφήμιση καθεαυτή.<br />Χρησιμοποιήστε τις διαφημίσεις PPC για να δείτε πωλήσεις.</h3>
                                <p>
                                    Οι διαφημίσεις Google είναι ιδιαίτερα ισχυρές εάν χρησιμοποιούνται σε μια στρατηγική παγκόσμιας
                                    προβολής. Η SEA (Search Engine Advertising) μπορεί να συμπληρώσει τις τεχνικές SEO. Για να έχετε την
                                    καλύτερη και μέγιστη προβολή και επισκεψιμότητα, είναι πάντα καλύτερο να κυριαρχήσετε τόσο στα
                                    οργανικά
                                    όσο και στα πληρωμένα αποτελέσματα στη Google. Για να γίνει αυτό, η ψηφιακή στρατηγική μάρκετινγκ
                                    πρέπει
                                    να ενσωματώσει SEO και SEA μαζί. Αυτό είναι που κάνουμε εμείς στη Think Plus κάθε μέρα!</p><p>

                                    Σας βοηθάμε να χτίσετε τη στρατηγική σας με την SEA ως ένα από τα εργαλεία για την ενίσχυση των
                                    αποτελεσμάτων σας, μαζί με το SEO φυσικά, SEO + SEA = SEM , αλλά και άλλα κανάλια και εργαλεία
                                    μάρκετινγκ όπως οι διαφημίσεις στο Facebook, οι διαφημίσεις στο Instagram και πολλά άλλα.</p>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <GatsbyImage
                                    image={data.photo7.childImageSharp.gatsbyImageData}
                                    alt='Search Engine Advertising'
                                    className="mb-4 mb-md-0" />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col text-align-center">
                                <h3 className="h6">Στοχοποίηση κοινού και εν δυνάμει πελατών.</h3>
                                <p>Ξαναστοχεύουμε τους επισκέπτες κατά τη διάρκεια του ταξιδιού τους στην ιστοσελίδα.
                                Οι διαφημίσεις Google προσφέρουν έναν πολύ ισχυρό τρόπο στοχοποίησης του κοινού. Σας επιτρέπουν να
                                ξαναεπικοινωνήσετε
                                με τους χρήστες ή τους πελάτες που δεν ολοκλήρωσαν όλες τις ενέργειες που εσείς θα θέλατε, να
                                στοχεύσετε παρόμοια
                                άτομα (με βάση την τρέχουσα πελατεία σας, για παράδειγμα), να προβάλλετε διαφημίσεις σε άτομα που
                                έδειξαν κάποια ενδιαφέροντα που ταιριάζουν με τα προϊόντα ή τις υπηρεσίες σας και πολλά άλλα.</p>

                                <p>Χρησιμοποιώντας τα audiences, μεγιστοποιείτε τις επενδύσεις σας, δημιουργείτε προσαρμοσμένες
                                καμπάνιες και διαφημίσεις
                                που δημιουργούνται ειδικά για τα “κοινά - στόχο”. Με αυτά, μπορείτε επίσης να χρησιμοποιήσετε τον
                                προϋπολογισμό σας
                                για πολύ συγκεκριμένους σκοπούς, όπως είναι η εκ νέου διάδραση με τους χρήστες που αποχώρησαν από
                                το καλάθι αγορών τους.</p>

                                <p>To ξέρουμε σας ακούγεται εξαιρετικό, αλλά ... απλά είναι η διαφήμιση στη Google; Εάν μπορούμε να
                                δημιουργήσουμε
                                τέτοιες στρατηγικές για το “κοινό-στόχο” σας στη διαφημίσεις Google, φανταστείτε τι μπορούμε να
                                στις Διαφημίσεις στο
                                Facebook, το Instagram και σε οποιαδήποτε άλλη πλατφόρμα διαφήμισης ή κοινωνικού δικτύου που έχει το δικό της Pixel.</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <h3 className="h6">Retargeting &amp; Remarketing.</h3>
                                <p>Αυξήστε την προβολή και την απόδοση της επένδυσης σας (ROI) και όχι το budget σας.
                                Ξαναστοχεύστε τα άτομα που επισκάφθηκαν τον ιστότοπό σας αλλά δεν έγιναν πελάτες. Αυτός είναι ένας
                                εύκολος τρόπος να
                                κάνετε CRO (Coversion Rate Optimization) και να μεγιστοποιήσετε τις επενδύσεις σας. Με αυτό τον
                                τρόπο εστιάζετε σε ένα
                                ειδικευμένο κοινό και πληρώνετε λιγότερα για κάθε κλικ. Είναι ακόμη δυνατό να αναπτυχθούν πολύ
                                λεπτομερείς στρατηγικές
                                επαναληπτικού μάρκετινγκ, οι οποίες θα επωφεληθούν πλήρως από αυτή τη προσέγγιση. Για παράδειγμα,
                                μπορούμε να
                                επαναπροσεγγίσουμε μόνο τους ανθρώπους που πρόσθεσαν ένα προϊόν στο καλάθι τους ή είδαν μια
                                συγκεκριμένη σελίδα προϊόντος.</p>
                                <p>Εάν ο ιστότοπός σας είναι ηλεκτρονικό κατάστημα, μπορούμε να σας βοηθήσουμε ακόμη και να
                                ρυθμίσετε καμπάνιες
                                δυναμικού remarketing. Αυτός ο τύπος καμπανιών απαιτεί ροές προϊόντων και ορισμένα συγκεκριμένα
                                pixels που
                                επικοινωνούν με τις πλατφόρμες διαφήμισης που θέλετε να χρησιμοποιήσετε για να δώσετε λεπτομέρειες
                                σχετικά με τη
                                συμπεριφορά των επισκεπτών σας. Επομένως, ας είμαστε ειλικρινείς, είναι κάτι περισσότερο από μια
                                απλή καμπάνια
                                remarketing. Αλλά παρόλα αυτά μπορούν να φέρουν εξαιρετικά αποτελέσματα.</p>
                                <p>Ο στόχος τους;</p>
                                <p>
                                    Δείξτε ξανά στους επισκέπτες σας τα προϊόντα που είδαν στον ιστότοπό σας, αλλά δεν αγόρασαν, για
                                    να τους ωθήσετε να
                                    ολοκληρώσουν την παραγγελία τους. Είναι ένας τέλειος τρόπος για να ξαναεπικοινωνήσετε με τους
                                    χρήστες βάσει αυτού που
                                    τους ενδιαφέρει στον ιστότοπό σας.

                                    Ακόμη ας μην ξεχνάμε το Google Shopping. Aυτό είναι ένα εξαιρετικό εργαλείο για ιστότοπους
                                    ηλεκτρονικού εμπορίου.
                                    Μπορείτε να δείτε τα προϊόντα σας στην κορυφή των αποτελεσμάτων της Google. Αυτός είναι ένας πολύ
                                    καλός τρόπος να
                  εμφανιστούν σε κρίσιμες στιγμές, όπως η φάση σύγκρισης ή οι φάσεις έρευνας.</p>
                            </div>

                            <div className="col-md-4 d-flex flex-column justify-content-around">
                                <GatsbyImage image={data.photo8.childImageSharp.gatsbyImageData} alt='retargeting' />
                                <GatsbyImage
                                    image={data.photo9.childImageSharp.gatsbyImageData}
                                    alt='remarketing'
                                    className="mb-4 mb-md-0" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="googleads__products">
                    <div className="container small">
                        <div className="row">
                            <div className="col text-align-center">
                                <h2 className="h4">Ποιά είναι τα προϊόντα της Google;<span className="dec-element--line-horizontal" aria-hidden="true"></span></h2>
                            </div>
                        </div>

                        <div className="row mb-4 align-items-center">
                            <div className="col-md-6 order-0 order-md-2">
                                <GatsbyImage
                                    image={data.search.childImageSharp.gatsbyImageData}
                                    alt='Google search'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-6 order-0 order-md-1">
                                <h3 className="h6">1. Google Search.</h3>
                                <p>Ένας χρήστης χτυπά μια λέξη-κλειδί στη Google και η επιχείρησή σας αναδύεται αμέσως. Ακούγεται σαν
                                ένα όνειρο, σωστά;
                                Αυτό μπορεί να κάνει μια στρατηγική καμπάνια του AdWords. Όταν προσεγγίζετε τη βοήθειά μας στο Google
                                Search, μπορείτε
                                να περιμένετε τη διαφήμισή σας να λειτουργεί χωρίς καθυστερήσεις και λάθη. Κανένα άγχος, απλά αποτελέσματα.</p>
                            </div>
                        </div>


                        <div className="row mb-4 align-items-center">
                            <div className="col-md-6">
                                <GatsbyImage
                                    image={data.display.childImageSharp.gatsbyImageData}
                                    alt='Google display network'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-6">
                                <h3 className="h6">2. Google Display Network.</h3>
                                <p>Δεν ενδιαφέρεστε να προωθήσετε την επιχείρησή σας τόσο επιθετικά…Κανένα πρόβλημα. Το δίκτυο Display
                                της Google σάς
                                δίνει τη δυνατότητα να δημιουργείτε μια εκλεπτυσμένη εικόνα για το brand σας. Εδώ, η διαφήμισή σας
                                εμφανίζεται σε
                                άλλους επιτυχημένους ιστότοπους, βίντεο και εφαρμογές που το κοινό-στόχος σας είναι ήδη πιθανότατα εκεί.</p>
                            </div>
                        </div>

                        <div className="row mb-4 align-items-center">
                            <div className="col-md-6 order-0 order-md-2">
                                <GatsbyImage
                                    image={data.shopping.childImageSharp.gatsbyImageData}
                                    alt='Google shopping'
                                    className="mb-4 mb-md-0" />
                            </div>
                            <div className="col-md-6 order-0 order-md-1">
                                <h3 className="h6">3. Google Shopping.</h3>
                                <p>
                                    Μην ντρέπεστε, δείτε τα προϊόντα σας με μια πλούσια σε εικόνα, διαφήμιση που εμφανίζεται στις σελίδες
                                    αναζήτησης. To
                                    Google shopping παίρνει τους χρήστες κοιτάζοντας αυτό που έχετε να προσφέρετε πριν κάνουν καν κλικ
                                    στον ιστοσελίδα
                                    σας. Αυτό σημαίνει λιγότερα αποτυχημένα κλικ και πιο κερδοφόρες μετατροπές.</p>
                            </div>
                        </div>

                        <div className="row mb-5 align-items-center">
                            <div className="col-md-6">
                                <GatsbyImage
                                    image={data.remarketing.childImageSharp.gatsbyImageData}
                                    alt='Google remarketing'
                                    className="mb-4 mb-md-0" />
                            </div>

                            <div className="col-md-6">
                                <h3 className="h6">4. Google Remarketing.</h3>
                                <p>Ως ψηφιακή επιχείρηση, υπάρχουν λίγα πράγματα πιο αποθαρρυντικά από έναν αναποφάσιστο πελάτη που
                                εγκατέλειψε το
                                εικονικό καλάθι αγορών του.
                                Το remarketing της Google στέλνει ένα έξυπνο και έμμεσο μήνυμα στους χρήστες που έχουν επισκεφτεί τον
                                ιστότοπό σας και
                                έξυπνα τους ξαναφέρνει μέσα.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container small">
                        <div className="row mb-4">
                            <div className="col-md-3 offset-md-1">
                                <h2 className="h4"><span className="dec-element--line-horizontal" aria-hidden="true"></span>Τι είναι το PPC και πως μπορεί να αύξηση την επισκεψιμότητα της ιστοσελίδας μου;</h2>
                            </div>

                            <div className="col-md-8">
                                <GatsbyImage
                                    image={data.photo10.childImageSharp.gatsbyImageData}
                                    alt='PPC - Pay per click'
                                    className="mb-4 mb-md-0" />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col">
                                <p>Γνωρίζατε ότι κατά μέσο όρο οι επιχειρήσεις πραγματοποιούν κέρδη 3€ για κάθε 1,60€ που δαπανώνται στο AdWords; Ή ότι
                                βάσει του όγκου αναζητήσεων του AdWords, αυτό εμφανίζει τις τρεις πρώτες πληρωμένες διαφημίσεις στο Google οι οποίες
                                προσελκύουν τελικά, σχεδόν το ήμισυ των συνολικών κλικ στη σελίδα;</p>
                                <p>
                                    Όταν γίνει σωστά, η δύναμη της διαφήμισης pay-per-click μπορεί να είναι εκπληκτική για τις επιχειρήσεις. Είναι ο πιο
                                    γρήγορος τρόπος σύνδεσης με χρήστες που αναζητούν να αγοράσουν ένα προϊόν ή να βρουν μια υπηρεσία.</p>
                                <p>
                                    Εδώ στη Think Plus, κάναμε τo PPC ένα από τα πιο αποτελεσματικά εργαλεία στην ψηφιακή μας εργαλειοθήκη, τόσο πολύ,
                                    ώστε μας δόθηκε η σφραγίδα της Google ως «Google Partner».</p>
                                <p>
                                    Όχι μόνο αυτό, μπορούμε να σας προσφέρουμε την ευκαιρία να μάθετε από την ομάδα των
                                    ειδικών μας λάτρεις στο PPC για την εκπαίδευση του Google AdWords, ώστε να μπορείτε να αναπτύξετε τις δεξιότητες που
                                    χρειάζεστε για να ξεπεράσετε τους ανταγωνιστές σας.</p>
                                <p>
                                    Μην ανησυχείτε όμως, γιατί η ομάδα της Think Plus επενδύει σε εργαλεία και γνώση, διατηρώντας πάντα αναλλοίωτη την πελατοκεντρική προσέγγιση για κάθε πελάτη και ζητούμενο.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="background__theme--dark mb-5">
                        <SectionHeader
                            title="Google Ads"
                            text="FAQ"
                        />
                    </div>
                    <div className="container medium">
                        <div className="row">
                            <div className="col-md-6">
                                <Accordion
                                    className="accordion--theme"
                                    allowZeroExpanded={true}
                                >
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι τα Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Τα Google Ads (πρώην AdWords) είναι η διαφημιστική πλατφόρμα της Google. Επιτρέπει σε διαφημιστές και επιχειρήσεις να διαφημίσουν προιοντα και υπηρεσιες σε ολόκληρο το δίκτυο της Google (πχ Google Search, YouTube κτλ). </p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Χρειάζομαι Google Ads (πρώην AdWords) για την επιχείρηση μου;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Όλες οι επιχειρήσεις χρειάζονται ενέργειες προώθησης και διαφήμισης. Άλλες σε λιγότερο και άλλες περισσότερο. </p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Είναι δύσκολη η διαχείριση των Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Τα Google Ads (πρώην AdWords) προσφέρουν πολλαπλές εμπειρίες χρήστη ανάλογα με το επίπεδο γνώσεων που έχει. Πχ η εμπειρία χρήστη για τοπικές καμπάνιες είναι συντριπτικά πιο εύκολη σε σχέση με την εμπειρία χρήστη για πρόσβαση σε όλες τις καμπάνιες των Google Ads (πρώην AdWords). Οπότε, εξαρτάται από το επίπεδο γνώσεων του χρήστη.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι δομή λογαριασμού Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Η δομή λογαριασμού επιτρέπει την καλύτερη διαχείριση και αξιολόγηση των προωθητικών ενεργειών μιας επιχείρησης ή ενός agency. Η πιο απλή δομή λογαριασμού είναι: λογαριασμός &#62; καμπάνια &#62; ομάδα διαφημίσεων &#62; διαφήμιση</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι KPIs στα Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Τα KPIs στα Google Ads (πρώην AdWords) είναι δείκτες που επιτρέπουν σε διαφημιστές και επιχειρήσεις να παρακολουθούν το επίπεδο επιτυχίας των προωθητικών ενεργειών τους στα Google Ads (πρώην AdWords). Κάθε προωθητική ενέργεια έχει και τους δικούς της δείκτες.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι οι επεκτάσεις (extensions) στα Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Οι επεκτάσεις εμπλουτίζουν τις διαφημίσεις των Google Ads (πρώην AdWords)  με πρόσθετες πληροφορίες. Στατιστικά αυτές οι πληροφορίες αυξάνουν την αναλογία κλικ προς αριθμό εμφανίσεων και αυτό μεταφράζεται σε αύξηση πωλήσεων.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι ο δείκτης ποιότητας στα Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ο δείκτης των Google Ads (πρώην AdWords) είναι μια εκτίμηση του συνόλου της διαφημιστικής ενέργειας. Το σύνολο της διαφημιστικής ενέργειας εμπεριέχει: διαφήμιση, λέξεις-κλειδιά, σελίδες προορισμού (landing pages). Ένας υψηλότερος δείκτης ποιότητας οδηγει σε χαμηλότερα κόστη κλικ και αυξημένες πωλήσεις.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Tι είναι το CTR;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Η αναλογία κλικ προς αριθμό εμφανίσεων ή το CTR (click-through-rate) είναι ένας δείκτης που χρησιμοποιείται για τη μέτρηση της επιτυχίας μιας διαδικτυακής διαφημιστικής καμπάνιας. Παρουσιάζεται ως ποσοστό, υπολογιζόμενο με βάση: τον αριθμό των κλικ που λαμβάνει η διαφήμισή σας διαιρούμενα με τον αριθμό των φορών που εμφανίζεται η διαφήμισή σας (αποκαλούμενες εμφανίσεις). Το ποσοστό αναφοράς για το CTR που προσδιορίζει η Google είναι 1-2% (για τη διαφήμιση αναζήτησης).</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το CPA;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Το κόστος ανά απόκτηση (Cost per Acquisition) - επίσης αναφέρεται ως CPA (Cost Per Action). Είναι η μέτρηση που χρησιμοποιείται για τον προσδιορισμό του κόστους σας ανά πώληση / ενέργεια από τη διαφήμισή σας. Αυτή είναι μια βασική μέτρηση για τον προσδιορισμό της απόδοσης της επένδυσής σας.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πόσο μου κοστίζει κάθε κλικ;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Αυτό εξαρτάται από τον τομέα της επιχείρηση και τους ανταγωνιστές σας. Με τα Google Ads (πρώην AdWords), μια σειρά από στοχευμένες φράσεις-κλειδιά μπορεί να ερευνηθεί και τελικά να επιλεχθεί από τον χρήστη - υποψήφιο πελάτη. Όταν αυτές οι λέξεις-κλειδιά χρησιμοποιούνται σε μια αναζήτηση από έναν χρήστη, θα ενεργοποιήσουν την εμφάνιση μιας διαφήμισης. Οι προσφορές τοποθετούνται για θέσεις διαφημίσεων. Οι τιμές των προσφορών μπορούν να κυμανθούν από 0,05 έως το ανώτερο τέλος των 100€ ανά κλικ. Οι τιμές προσφοράς για τις λέξεις-κλειδιά για τις περισσότερες επιχειρήσεις κυμαίνονται από 0,20€ έως 5,00€ ανά κλικ. Αυτό θα καθοριστεί από την έρευνά μας και ποιοι τύποι αντιστοίχισης έχουν επιλεχθεί. Η τιμή προσφοράς εξαρτάται από το πόσο ψηλά θέλετε να βλέπετε τις διαφημίσεις σας και πόσος ανταγωνισμός υπάρχει για τις φράσεις-κλειδιά που έχουν επιλεχθεί.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Μπορώ να εντοπίσω τηλεφωνήματα και emails που προήλθαν λόγω του Google Ads (πρώην AdWords);</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ναι, χρησιμοποιώντας τους αριθμούς προώθησης Google, τα κουμπιά κλήσεων για κινητά και την παρακολούθηση συμπλήρωσης της φόρμας. Έτσι θα μπορείτε να καταλάβετε τι λειτουργεί και τι όχι.</p></AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div className="col-md-6">
                                <Accordion
                                    className="accordion--theme"
                                    allowZeroExpanded={true}
                                >
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι καλύτερο; Το κοινό στόχος να επισκέπτεται μία γενική σελίδα ή μία πολύ συγκεκριμένη υποσελίδα;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Πρέπει να επιλεχθεί η πιο σχετική με τον όρο αναζήτησης, σελίδα. Εάν ο όρος απαιτεί εξήγηση και μια ειδική σελίδα, τότε μπορεί να δικαιολογηθεί μια landing page. Αυτό θα συνέβαινε στην περίπτωση ενός νέου προϊόντος, μιας νέας λέξης ή μιας μοναδικής υπηρεσίας. Αν πρόκειται για έναν γενικό όρο που περιγράφει την επιχείρηση ως σύνολο, τότε μπορεί να είναι πιο κατάλληλη η αρχική σελίδα.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Tι διαφημιστικό budget πρέπει να διαθέσω;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ο προϋπολογισμός σας πρέπει να βασίζεται στους επιχειρηματικούς στόχους σας και στις προωθητικές ενέργειες των ανταγωνιστών σας. Εάν ξέρετε τον αριθμό των νέων πελατών που πρέπει να έχετε για να αυξήσετε ή να διατηρήσετε τα έσοδα σε σύγκριση με το ποσοστό μετατροπής σας, τότε υπολογίστε πόσα κλικ χρειάζεστε ανά ημέρα για να επιτύχετε αυτόν τον στόχο. Ο προϋπολογισμός σας θα πρέπει να το επιτρέπει αυτό, συν ένα μικρό περιθώριο ασφαλείας. Ο σημαντικότερος παράγοντας για τον καθορισμό του προϋπολογισμού σας είναι η αποτελεσματικότητα έναντι της τιμής. Αν εξοικονομείτε πολλά, περιορίζοντας τον προϋπολογισμό σας, τότε μπορεί να μην έχετε τα επίπεδα επισκεψιμότητας που χρειάζεστε. Από την άλλη πλευρά, αν ξοδεύετε περισσότερα από όσα μπορείτε να αντέξετε οικονομικά, η επιτυχία σας δεν θα είναι βιώσιμη.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Γιατί η χρέωση των Google Ads (πρώην AdWords) ήταν μεγαλύτερη από το budget που είχα καθορίσει;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ο λογαριασμός σας σπάνια θα είναι μεγαλύτερος από τον προϋπολογισμό σας μηνιαίως. Ωστόσο, εάν έχετε ένα υπόλοιπο από τον τελευταίο μήνα, μπορεί να προστεθεί στον επόμενο λογαριασμό.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Μπορώ να κάνω διαφήμιση για δύο παρόμοια websites, από διαφορετικούς λογαριασμούς αλλά στοχεύοντας ίδιες λέξεις κλειδιά;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Όχι. Αυτό αντίκειται στην πολιτική των Google Ads (πρώην AdWords). Ακόμη και όταν πρόκειται για Google Ads (πρώην AdWords), η Google θέλει να προσφέρει στους χρήστες μια καλή εμπειρία - αυτό σημαίνει μια γνήσια ποικιλία προτάσεων και διαφημίσεων.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Γιατί δεν μου εμφανίζονται οι διαφημίσεις μου στις δικές μου αναζητήσεις;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>Για πολλούς λόγους :</p>
                                            <ul>
                                                <li>Ο ημερήσιος προϋπολογισμός σας έχει δαπανηθεί.</li>
                                                <li>Η προσφορά σας είναι πολύ χαμηλή για να εμφανιστεί η διαφήμιση στην πρώτη σελίδα.</li>
                                                <li>Η πιστωτική / χρεωστική σας κάρτα απορρίφθηκε.</li>
                                                <li>Η διαφήμιση απορρίφθηκε.</li>
                                                <li>Δεν βρίσκεστε στην τοποθεσία στην οποία στοχεύετε.</li>
                                                <li>Οι διαφημίσεις έχουν ρυθμιστεί να προβάλλονται σε συγκεκριμένες ώρες.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πόσο συχνά με τιμολογεί η google;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Κάθε τριάντα ημέρες ή όταν φτάσουμε το όριο του budget</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Οι διαφημίσεις μου στη google εμφανίζονται σωστά στα κινητά;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ναι, μπορείτε να προβάλλετε τις διαφημίσεις σας σε κινητές συσκευές με πλήρη προγράμματα περιήγησης (smartphones και tablet υψηλής τεχνολογίας). Μπορείτε να προβάλλετε διαφημίσεις κειμένου, εικόνας, εφαρμογών και διαφημίσεων HTML5 σε κινητές συσκευές.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Μπορούν οι διαφημίσεις μου να παίζονται συγκεκριμένες ημέρες και ώρες;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ναι, είναι σχετικά απλό να ορίσετε συγκεκριμένες ώρες και ημέρες που θέλετε να εμφανίζεται η διαφήμισή σας. Με τη στρατηγική επιλογή των χρόνων κατά τις οποίες είναι πιθανότερο να αναζητηθεί το προϊόν και η υπηρεσία σας, μπορείτε να αξιοποιήσετε στο έπακρο τις δαπάνες σας στα Google Ads (πρώην AdWords). Οι ακριβείς ώρες και ημέρες θα εξαρτηθούν από την αγορά, τη θέση και την τοποθεσία σας.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πόσο συγκεκριμένη περιοχή μπορώ να στοχεύσω για τις διαφημίσεις μου;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Μπορείτε να καθορίσετε μια περιοχή τόσο μικρή όσο μια ακτίνα 1χλμ γύρω από μια συντεταγμένη GPS - για παράδειγμα, τη θέση του καταστήματός σας ή του γραφείου σας. Πολλές επιχειρήσεις στοχεύουν σε ένα συγκεκριμένο προάστιο ή ομάδα προαστίων. Μπορείτε να στοχεύσετε ολόκληρη τη χώρα (ή πολλές χώρες), αν θέλετε. Είναι όλα σχετικά με το να γνωρίζετε πού είναι το κοινό-στόχος σας.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Η Google στέλνει τιμολόγιο για τις χρεώσεις;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Φυσικά. Δηλώστε τα φορολογικά στοιχεία της επιχείρησής σας και θα λαμβάνετε τιμολόγιο που μπορείτε να καταχωρείτε στα έξοδά σας, ως διαφημιστική δαπάνη.</p></AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Υπάρχουν προϊόντα που δεν μπορούν να διαφημιστούν;</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel><p>Ναι υπάρχει μία ολόκληρη λίστα από προϊόντα και υπηρεσίες που δεν μπορούν να διαφημιστούν, βάσει πολιτικής της Google <a href="https://support.google.com/adspolicy/answer/6008942?hl=el" target="_blank" rel="nofollow noopener">https://support.google.com/adspolicy/answer/6008942?hl=el</a></p></AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactForm />
            </article>
        </Layout>
    </>;
}

export default pageGoogleads
